/* eslint-disable jsx-a11y/anchor-is-valid, react/jsx-props-no-spreading */
import { useUser } from '@folklore/auth';
import { useRoutes, useUrlGenerator } from '@folklore/routes';
import { DataProvider } from '@micromag/data';
import '@micromag/intl/locale/fr';
import Viewer from '@micromag/viewer';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation, useRoute } from 'wouter';

// import { usePopularDocuments } from '../../hooks/useDocuments';
import * as AppPropTypes from '../../lib/PropTypes';

import { useApi } from '../../contexts/ApiContext';
import { usePromotions } from '../../contexts/PromotionsContext';
import { useSite } from '../../contexts/SiteContext';
import DocumentsList from '../lists/DocumentsList';
import DocumentTitle from '../typography/DocumentTitle';
import SectionTitle from '../typography/SectionTitle';

import styles from '../../styles/documents/micromag-document.module.css';

/* prettier-ignore */
import '../../styles/micromag.css';

// const Viewer = null;

const propTypes = {
    id: PropTypes.string,
    slug: PropTypes.string,
    title: PropTypes.string,
    micromag: PropTypes.shape({
        components: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string,
            }),
        ),
    }).isRequired,
    metadata: PropTypes.shape({
        // eslint-disable-next-line react/forbid-prop-types
        subscriptionPromotion: PropTypes.object,
    }),
    image: AppPropTypes.image,
    paused: PropTypes.bool,
    current: PropTypes.bool,
    entered: PropTypes.bool,
    onEnter: PropTypes.func,
    onLeave: PropTypes.func,
    onComplete: PropTypes.func,
    onProgress: PropTypes.func,
    className: PropTypes.string,
};

const defaultProps = {
    id: null,
    title: null,
    slug: null,
    image: null,
    metadata: null,
    paused: false,
    current: false,
    entered: false,
    onEnter: null,
    onLeave: null,
    onComplete: null,
    onProgress: null,
    className: null,
};

function MicromagDocument({
    id,
    title,
    slug,
    image,
    micromag,
    metadata,
    paused,
    entered,
    current,
    onEnter,
    onLeave,
    onComplete,
    onProgress,
    className,
}) {
    const url = useUrlGenerator();
    const { handle: siteHandle } = useSite();
    const { organisation = null, components = [] } = micromag || {};
    const {
        subscriptionPromotion = null,
        collections = [],
        micromagNumber = null,
    } = metadata || {};
    const isMicromagUrbania =
        (collections || []).findIndex(
            ({ slug }) => slug === 'micromag-urbania' || slug === 'micromag-france',
        ) !== -1;
    const { branding = null } = organisation || {};
    const routes = useRoutes();
    const [, params] = useRoute(routes.micromag);
    const user = useUser();
    const api = useApi();
    const { path: screenParam = null } = params || {};
    const routeScreenIndex = parseInt(screenParam || '1', 10) - 1;
    const [screenIndex, setScreenIndex] = useState(routeScreenIndex);
    const { id: currentScreenId = null } = components[screenIndex] || {};
    const basePath =
        slug !== null
            ? url('micromag', {
                  slug,
              })
            : null;
    const [, setLocation] = useLocation();
    const [screenViewed, setScreenViewed] = useState([]);
    useEffect(() => {
        if (screenIndex !== routeScreenIndex) {
            setScreenIndex(routeScreenIndex);
        }
    }, [routeScreenIndex]);
    const onScreenChange = useCallback(
        (screen) => {
            const newScreenIndex = components.findIndex(
                ({ id: screenId }) => screenId === screen.id,
            );

            let progress = null;
            if (screenViewed.indexOf(screen.id) === -1) {
                const newScreenViewed = [...screenViewed, screen.id];
                setScreenViewed(newScreenViewed);
                progress = newScreenViewed.length / components.length;
            }
            const lastIndex = components.length - 1;
            setLocation(
                url('micromag', {
                    slug,
                    path: newScreenIndex + 1,
                }),
            );
            setScreenIndex(newScreenIndex);
            if (newScreenIndex > 0 && newScreenIndex < lastIndex && onEnter !== null) {
                onEnter();
            } else if ((newScreenIndex === 0 || newScreenIndex === lastIndex) && onLeave !== null) {
                onLeave();
            }

            if (progress !== null && onProgress !== null) {
                onProgress(progress);
            }

            if (progress === 1 && onComplete !== null) {
                onComplete();
            }
        },
        [
            components,
            setLocation,
            slug,
            entered,
            onEnter,
            onLeave,
            screenViewed,
            onComplete,
            onProgress,
        ],
    );

    const titleParts = title !== null ? title.split('–') : null;
    const [mainTitle = null, badgeTitle = null] = titleParts || [];

    const viewerMenuHeader = (
        <div className={styles.menuHeader}>
            {badgeTitle !== null ? <div className={styles.badge}>{badgeTitle}</div> : null}
            {mainTitle !== null ? (
                <DocumentTitle className={styles.title}>{mainTitle}</DocumentTitle>
            ) : null}
        </div>
    );

    const viewerMenuFooter = (
        <div className={styles.menuFooter}>
            <DocumentsList
                query={{
                    type: 'micromag',
                    collection: `micromag-${siteHandle}`,
                    exclude: id,
                }}
                cardType="over"
                cardRatio="micromag"
                cardTheme="outline"
                minColumns={3}
                header={
                    <SectionTitle className={styles.title}>
                        <FormattedMessage defaultMessage="Plus de micromags" />
                    </SectionTitle>
                }
            />
        </div>
    );

    const onEnd = useCallback(() => {
        if (onLeave !== null) {
            onLeave();
        }
    }, [onLeave]);

    const onClose = useCallback(() => {
        if (onLeave !== null) {
            onLeave();
        }
    }, [onLeave]);

    const { addPromotion, removePromotion } = usePromotions();
    useEffect(() => {
        if (id === null || !isMicromagUrbania) {
            return () => {};
        }
        const { screenIndex: subscriptionScreenIndex = null } = subscriptionPromotion || {};
        const finalSubscriptionScreenIndex =
            subscriptionScreenIndex !== null ? subscriptionScreenIndex : 3;
        const shouldAllowCurrentScreen = routeScreenIndex >= finalSubscriptionScreenIndex;
        const promotionId =
            micromagNumber !== null
                ? `subscription_micromag_${micromagNumber}`
                : 'subscription_micromag';
        const promotion = {
            id: promotionId,
            handle: promotionId,
            type: 'subscription',
            placement: 'modal',
            force: true,
            theme: 'full',
            subscription: 'micromag',
            title: 'Abonnez-vous<br/>pour continuer',
            description:
                'Vous recevrez nos micromags gratuitement et en exclusivité chaque samedi.',
            titleLogin: 'Connectez-vous<br/>pour continuer',
            descriptionLogin: 'Vous aurez accès à tous les micromags en exclusivité.',
            trigger: {
                event: 'one',
                triggers: [
                    shouldAllowCurrentScreen
                        ? {
                              event: 'pageview',
                              pageMatch: {
                                  type: 'micromag',
                              },
                              maxScreenIndex: routeScreenIndex - 1,
                          }
                        : null,
                    {
                        event: 'pageview',
                        pageMatch: {
                            type: 'micromag',
                        },
                        minScreenIndex: shouldAllowCurrentScreen
                            ? routeScreenIndex + 1
                            : finalSubscriptionScreenIndex,
                    },
                ].filter((it) => it !== null),
            },
            ...subscriptionPromotion,
        };
        addPromotion(promotion);
        return () => {
            removePromotion(promotion.id);
        };
    }, [id, subscriptionPromotion, isMicromagUrbania]);

    return (
        <div
            className={classNames([
                styles.container,
                {
                    'drag-x-disabled': entered,
                    [className]: className !== null,
                },
            ])}
        >
            <DataProvider apiBaseUrl="https://microm.ag/api">
                <div className={styles.content}>
                    {micromag !== null ? (
                        <Viewer
                            story={micromag}
                            theme={branding}
                            locale="fr"
                            visitor={user}
                            paused={paused}
                            basePath={basePath}
                            screen={currentScreenId}
                            closeable={false}
                            className={styles.micromag}
                            trackingDisabled={!current}
                            neighborScreensActive={entered ? 1 : 0}
                            withoutRouter
                            menuHeader={viewerMenuHeader}
                            menuFooter={viewerMenuFooter}
                            withoutGestures={!entered}
                            // withoutTransitions
                            // withoutMenu
                            // withoutScreensMenu
                            // withoutShareMenu
                            // googleApiKey={googleApiKey}
                            // onViewModeChange={onViewModeChange}
                            onScreenChange={onScreenChange}
                            // onMenuChange={onMenuChange}
                            // onInteraction={onInteraction}
                            // onClose={onClose}
                            // onEnd={onEnd}
                            // withoutPlaybackControls={withoutPlaybackControls}
                            withNavigationHint="hand"
                        />
                    ) : null}
                </div>
            </DataProvider>
        </div>
    );
}

MicromagDocument.propTypes = propTypes;
MicromagDocument.defaultProps = defaultProps;

export default MicromagDocument;
